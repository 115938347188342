import { observable } from 'mobx';
import AppStore from './AppStore';

interface Stores {
  app: typeof AppStore;
}
const stores: Stores = {
  app: AppStore,
};

observable(stores);

export default stores;
