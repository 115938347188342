import { gql, useQuery } from '@apollo/react-hooks';

export const GET_CURRENT_GAME = gql`
  query GetCurrentGame {
    currentGame {
      id
      aceCount
      cardsCount
      hasWon
      hasCompleted
    }
  }
`;

export const useGetCurrentGame = () => {
  const data = useQuery(GET_CURRENT_GAME);
  return data.data;
};
