export interface Game {
  id: number;
  aceCount: number;
  cardsCount: number;
  hasWon: boolean;
  hasCompleted: boolean;
  createdAt: string;
}

export interface HandEntity {
  id: number;
  card: Card;
}

export interface Hand {
  id: number;
  gameId: number;
  handEntities: HandEntity[];
  game: Game;
}

type CardValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;

export enum Suite {
  spade = 'spade',
  diamond = 'diamond',
  club = 'club',
  heart = 'heart',
}

export interface Card {
  suite: Suite;
  value: CardValue;
}

export type Deal = Hand;
