import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { deal, startNewGame } from '../../actions';
import stores from '../../stores';
import styles from './GameActions.module.css';

const GameActions: FC = () => {
  const { currentGame } = stores.app;

  if (currentGame.hasCompleted) {
    return (
      <div className={styles.container}>
        <button className={styles.dealButton} type="button" onClick={startNewGame}>
          Start new game
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <button className={styles.dealButton} type="button" onClick={deal}>
        DEAL
      </button>
      <button className={styles.resetButton} type="button" onClick={startNewGame}>
        Reset
      </button>
    </div>
  );
};

export default observer(GameActions);
