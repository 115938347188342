import { FC } from 'react';
import styles from './Banner.module.css';

export enum BannerType {
  success = 'success',
  loss = 'loss',
}

interface BannerProps {
  type: BannerType;
}
const Banner: FC<BannerProps> = ({ type }) => {
  if (type === BannerType.success) {
    return (
      <div className={styles.banner}>
        <div className={styles.container}>
          <h1 className="ribbon">
            <strong className="ribbon-content">Winner</strong>
          </h1>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.banner}>
      <div className={styles.container}>
        <h1 className="ribbon">
          <strong className="ribbon-content">Lost</strong>
        </h1>
      </div>
    </div>
  );
};

export default Banner;
