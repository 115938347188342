import client from '../apollo';
import { CREATE_NEW_GAME } from '../queries/game/createNewGame';
import { GET_CURRENT_GAME } from '../queries/game/currentGame';
import { DEAL } from '../queries/game/deal';
import { GET_LAST_HAND } from '../queries/game/getLastHand';
import stores from '../stores';
import { Deal, Game, Hand } from '../stores/AppStore.types';

export const getCurrentGame = async () => {
  const { data } = await client.query<{ currentGame: Game }>({ query: GET_CURRENT_GAME });
  stores.app.setCurrentGame(data.currentGame);
};

export const getLastHand = async (gameId: number) => {
  const { data } = await client.query<{ getLastHand: Hand }>({
    query: GET_LAST_HAND,
    variables: { gameId },
  });
  stores.app.setLastHand(data.getLastHand);
};

export const resetGame = () => {
  // reset store
  // create a new game
};

export const deal = async () => {
  const { data } = await client.query<{ deal: Deal }>({ query: DEAL, fetchPolicy: 'no-cache' });
  const { deal: nextDeal } = data;
  stores.app.setCurrentGame(nextDeal.game);
  stores.app.setLastHand(nextDeal);
  stores.app.setDeal(nextDeal);
};

export const startNewGame = async () => {
  await client.query<{ newGame: Game }>({
    query: CREATE_NEW_GAME,
    variables: { input: null },
    fetchPolicy: 'no-cache',
  });
  const { data } = await client.query<{ deal: Deal }>({
    query: DEAL,
    fetchPolicy: 'no-cache',
  });
  stores.app.setCurrentGame(data.deal.game);
  stores.app.setLastHand(data.deal);
  stores.app.setDeal(data.deal);
};

export const setAppState = async () => {
  const { data: currentGame } = await client.query<{ currentGame: Game }>({
    query: GET_CURRENT_GAME,
    fetchPolicy: 'no-cache',
  });
  const { data: lastHand } = await client.query<{ getLastHand: Hand }>({
    query: GET_LAST_HAND,
    variables: { gameId: currentGame.currentGame.id },
  });

  stores.app.setCurrentGame(currentGame.currentGame);
  stores.app.setLastHand(lastHand.getLastHand);
};
