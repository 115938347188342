import { nanoid } from 'nanoid';
import { FC } from 'react';
import Card from './Card';
import { CardsProps } from './Cards.types';
import styles from './Cards.module.css';

const Cards: FC<CardsProps> = ({ cards = [] }) => {
  const cardItems = cards.map((card) => (
    <Card key={nanoid(10)} value={card.card.value} suite={card.card.suite} />
  ));

  return <div className={styles.container}>{cardItems}</div>;
};

export default Cards;
