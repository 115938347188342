import { gql, useQuery } from '@apollo/react-hooks';

export const GET_LAST_HAND = gql`
  query GetLastHand($gameId: Float!) {
    getLastHand(gameId: $gameId) {
      id
      gameId
      game {
        id
        cardsCount
      }
      handEntities {
        id
        card {
          id
          suite
          value
        }
      }
    }
  }
`;

export const useGetCurrentGame = () => {
  const data = useQuery(GET_LAST_HAND);
  return data.data;
};
