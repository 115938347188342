/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { setAppState } from './actions';
import Banner from './components/Banner';
import { BannerType } from './components/Banner/Banner';
import Cards from './components/Cards';
import GameActions from './components/GameActions';
import GameStatus from './components/GameStatus';
import stores from './stores';
import { Game } from './stores/AppStore.types';
import styles from './App.module.css';

const App = () => {
  const { app } = stores;

  useEffect(() => {
    setAppState();
  }, []);

  const getBannerType = (currentGame: Game) => {
    if (currentGame.hasCompleted && currentGame.hasWon) {
      return BannerType.success;
    }
    return BannerType.loss;
  };

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <GameStatus cardsCount={app.currentGame.cardsCount} aceCount={app.currentGame.aceCount} />
      </div>
      <Cards cards={app.lastHand.handEntities} />
      <div className={styles.actionsContainer}>
        <GameActions />
      </div>
      {app.currentGame.hasCompleted && <Banner type={getBannerType(app.currentGame)} />}
    </div>
  );
};

export default observer(App);
