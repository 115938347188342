import { FC } from 'react';
import { GameStatusProps } from './GameStatus.types';
import styles from './GameStatus.module.css';

const GameStatus: FC<GameStatusProps> = ({ cardsCount, aceCount }) => {
  return (
    <div className={styles.container}>
      <div className={styles.metricContainer}>
        <span className={styles.value}>{cardsCount}</span>
        <span className={styles.label}>Cards Left</span>
      </div>
      <div className="divider" />
      <div className={styles.metricContainer}>
        <span className={styles.value}>{aceCount}</span>
        <span className={styles.label}>Ace Left</span>
      </div>
    </div>
  );
};

export default GameStatus;
