import {
  ApolloClient,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { nanoid } from 'nanoid';

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Game':
        return `Game:${nanoid()}`;
      case 'Card':
        return `Card:${nanoid()}`;
      case 'HandEntity':
        return `HandEntity:${nanoid()}`;

      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});
const link = createHttpLink({
  uri: `http://ec2-3-141-16-182.us-east-2.compute.amazonaws.com:5000/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('V-JWT');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  name: 'uplift',
  queryDeduplication: false,
});

export default client;
