import { configure, makeAutoObservable } from 'mobx';
import { Deal, Game, Hand } from './AppStore.types';

configure({ enforceActions: 'always' });

class AppStore {
  currentGame: Game = {} as Game;

  lastHand: Hand = {} as Hand;

  deal: Deal = {} as Deal;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentGame(game: Game) {
    this.currentGame = game;
  }

  setLastHand(hand: Hand) {
    this.lastHand = hand;
  }

  setDeal(deal: Deal) {
    this.deal = deal;
  }

  reset = () => {
    this.currentGame = {} as Game;
    this.deal = {} as Deal;
  };
}

export default new AppStore();
