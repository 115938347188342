/* eslint-disable no-console */
import { gql, useQuery } from '@apollo/client';

export const DEAL = gql`
  query Deal {
    deal {
      id
      game {
        id
        aceCount
        hasCompleted
        hasWon
        cardsCount
      }
      handEntities {
        id
        card {
          id
          suite
          value
        }
      }
    }
  }
`;

export const useDeal = () => {
  const data = useQuery(DEAL);
  return data.data;
};
