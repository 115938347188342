import { gql } from '@apollo/react-hooks';

export const CREATE_NEW_GAME = gql`
  mutation CreateNewGame($input: CreateGameInput) {
    createGame(input: $input) {
      id
      userId
      cardsCount
      aceCount
      hasCompleted
      hasWon
      createdAt
    }
  }
`;
