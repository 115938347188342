import { FC } from 'react';
import { Card as CardItem, Suite } from '../../stores/AppStore.types';
import styles from './Card.module.css';

interface CardProps {
  value: CardItem['value'];
  suite: CardItem['suite'];
}

const Card: FC<CardProps> = ({ value, suite }) => {
  const getBgClass: Record<Suite, string> = {
    [Suite.club]: 'bg-club',
    [Suite.diamond]: 'bg-diamond',
    [Suite.heart]: 'bg-heart',
    [Suite.spade]: 'bg-spade',
  };

  return (
    <div className={`${styles.container} ${getBgClass[suite]} bg-auto bg-no-repeat bg-center`}>
      {value}
    </div>
  );
};

export default Card;
